import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/projects/Hero";
import Content1 from "../../sections/projects/Content1";
import Content2 from "../../sections/projects/Content2";
import CTA from "../../sections/projects/CTA";

const Projects = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Content1 className="pb-10 pb-sm-5 pb-lg-14 projects-content-1" />
        <Content2 className="pb-0 pb-lg-31" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Projects;
